import React, {CSSProperties, FC, useState} from 'react';
import moment, {Moment} from "moment";
import {Button, Icon} from "antd";
import {IconClockO, IconCrosshairsSolid} from "../icons";
import {TimeRangePickerPanel} from "../timeRangePicker/timeRangePickerPanel";
import {TimeInput} from "./timeInput";
import {LocalizationEnum, localize} from "../../localization";
import Trigger from 'rc-trigger';
import './timePicker.less';

interface DateTimePickerFooterProps {
    value?: Moment;
    onChange?: (date: Moment|undefined) => void;
    style?: CSSProperties;
    disabledHours?: number[];
    disabledMinutes?: number[];
}

export const TimePicker:FC<DateTimePickerFooterProps> = ({onChange, value, style, disabledHours, disabledMinutes}) => {

    const [timePickerVisible, setTimePickerVisible] = useState(false);
    const [selectedDate, setSelectedDate] = useState<Moment|undefined>();
    const currentDisplayValue = selectedDate || value || undefined;

    return (
        <Trigger
            getPopupContainer={(triggerNode) => triggerNode.parentNode as any}
            popupTransitionName={'slide-up'}
            destroyPopupOnHide
            stretch={['width']}
            popupVisible={timePickerVisible}
            onPopupVisibleChange={(visible) => {
                setTimePickerVisible(visible);
            }}
            popupAlign={{ points: ['tc', 'bc'], offset: [0, -314] }}
            action={['click']}
            popup={
                <TimeRangePickerPanel
                    value={currentDisplayValue}
                    minutesStep={1}
                    onChange={(value) => {}}
                    onSelect={(value) => {
                        const date = value.milliseconds(0).seconds(0);
                        setSelectedDate(date)
                        onChange?.(date);
                    }}
                    onOk={() => {
                        // клик по кнопке ОК
                        setTimePickerVisible(false);
                    }}
                    onSetCurrentTime={() => {
                        const curDate = moment().milliseconds(0).seconds(0);
                        const date =
                            selectedDate?.clone().hours(curDate.hours()).minutes(curDate.minutes()) ||
                            curDate;
                        setSelectedDate(date)
                        onChange?.(date);
                    }}
                    disabledHours={disabledHours}
                    disabledMinutes={disabledMinutes}
                />
            }
        >
            <div>
                <span className="ant-calendar-picker-input ant-input rr-time-picker" style={style}>
                    <TimeInput
                        value={currentDisplayValue}
                        onChange={(value) => {
                            setSelectedDate(value)
                            onChange?.(value);
                        }}
                        style={{ paddingRight: 28 }}
                    />
                    <span className="ant-time-picker-icon rr-time-picker-icon">
                        <Icon component={IconClockO} />
                    </span>
                </span>
            </div>
        </Trigger>
    );
};


// .ant-time-picker-icon