import { ColumnTypes, TableColumn } from './Table';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';
import { isDefined } from '../../shared/util/utils';
import { EntityType } from '../../../index';
import { LocalizationEnum, localize } from '../../localization';
import { Icon } from 'antd';
import { IconBell } from '../icons';
import { GRID_ACTIONS_COLUMN_WIDTH, GRID_IMAGE_COLUMN_WIDTH, GRID_PROBLEM_COLUMN_WIDTH, MAX_WIDTH } from '../../config/constants';
import { ProductVariantPopover } from '../../modules/main/inventory/variants/components/productVariantPopover/productVariantPopover';
import { NomenclatureEntityTypeCodeEnum, RentActivityFrameTypeCodeEnum } from '../../server';
import { SystemIcon, SystemIconType } from '../v2/systemIcon/systemIcon';
import DashboardDate from '../dashboardDate/dashboardDate';
import moment from 'moment';
import { BarcodePrint } from '../barcodePrint/barcodePrint';

type CreateProjectReferenceCountColumnGetDataProps = {
    baId: number;
    count: number;
    params?: {
        locationId?: number;
    };
};

/**
 * Утилитный класс для создания однотипных колонок в гридах
 */
export class GridColumnCreator {
    static createProblemSeverityColumn<T extends object>(): TableColumn<T> {
        return {
            title: <Icon style={{ fontSize: 21, paddingTop: 3 }} component={IconBell} />,
            dataIndex: 'problemSeverity' as any,
            type: ColumnTypes.Problem3,
            minWidth: GRID_PROBLEM_COLUMN_WIDTH,
            width: GRID_PROBLEM_COLUMN_WIDTH,
            maxWidth: GRID_PROBLEM_COLUMN_WIDTH,
            resizable: false,
            dontHide: true,
            sortable: true,
        };
    }

    /**
     * Создать описание для колонки с картинкой
     * @param column
     */
    static createImageColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__IMAGE),
            dataIndex: 'image' as any,
            type: ColumnTypes.Image,
            minWidth: GRID_IMAGE_COLUMN_WIDTH,
            width: GRID_IMAGE_COLUMN_WIDTH,
            maxWidth: GRID_IMAGE_COLUMN_WIDTH,
            sortable: false,
            resizable: false,
            defaultHidden: false,
            ...column,
        };
    }

    static createUserEmailColumn<T extends object>(
        getData: (row: T) => { baId?: number; id: number; email?: string; invited?: boolean }
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(50);
        return {
            title: 'E-Mail',
            dataIndex: 'email' as any,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { email, baId, id, invited } = getData(row);
                return email ? (
                    <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={email}>
                        {invited ? <span style={{ color: '#FC8C2F' }}>{email} </span> : <Link to={`/${baId}/users/${id}`}>{email}</Link>}
                    </div>
                ) : null;
            },
        };
    }

    static createShortNameColumn<T extends object>(
        entityType: EntityType,
        getData: (row: T) => { baId?: number; id?: number; name?: string; stopPropagation?: boolean },
        column?: TableColumn<T>
    ): TableColumn<T> {
        let maxLength = 30;
        const lengthData: Partial<Record<EntityType, number>> = {
            renter: 50,
            project: 50,
            offer: 50,
            simpleOrder: 50,
            subrent: 50,
            operation: 20,
            product: 75,
            kit: 75,
            variant: 30,
            location: 60,
            vehicle: 50,
            expense: 50,
            workPlanning: 50,
            'product-instances': 30,
        };
        maxLength = lengthData?.[entityType] ?? maxLength;

        const sizes = this.getColumnSizes(maxLength);

        return {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__NAME),
            dataIndex: 'shortName' as any,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { name, baId, id, stopPropagation } = getData(row);
                let className: string | undefined;
                let link: string | undefined;
                if (entityType === 'renter') link = `/${baId}/crm/counterparties/${id}`;
                else if (entityType === 'project') link = `/${baId}/projects/production/${id}`;
                else if (entityType === 'offer') link = `/${baId}/projects/offers/${id}`;
                else if (entityType === 'simpleOrder') link = `/${baId}/projects/simpleOrders/${id}`;
                else if (entityType === 'subrent') link = `/${baId}/subrent/shippings/${id}`;
                else if (entityType === 'operation') link = `/${baId}/history/operations/${id}`;
                else if (entityType === 'product') link = `/${baId}/inventory/products/${id}`;
                else if (entityType === 'kit') link = `/${baId}/inventory/kits/${id}`;
                else if (entityType === 'profession') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'variant') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'product-instances') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'location') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'vehicle') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'expense') className = `rr-grid-shortName-cell-hover`;
                else if (entityType === 'crewMember') link = `/${baId}/crew/crewMembers/${id}`;

                return (
                    <div className={'rr-grid-shortName-cell rr-grid-text-cell-left'} title={name}>
                        {link ? (
                            <Link onClick={stopPropagation ? (event) => event.stopPropagation() : undefined} to={link}>
                                {name}
                            </Link>
                        ) : (
                            <span className={className}>{name}</span>
                        )}
                    </div>
                );
            },
            dontHide: true,
            ...column,
        };
    }

    static createStatusColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__STATUS),
            dataIndex: 'stateCode' as any,
            type: ColumnTypes.Status,
            width: 130,
            maxWidth: MAX_WIDTH(130),
            resizable: false,
            sortable: true,
            ...column,
        };
    }

    /**
     * Создаем данные для колонки с пользователем
     * @param title Заголовок колонки
     * @param dataIndex По чему сортируем
     * @param getData Функция, преобразующая данные
     * @param column Переопределяем параметры если вдруг надо
     */
    static createUserColumn<T extends object>(
        getData: (row: T) => { baId?: number; id?: number; name?: string; hasOwn?: boolean; emptyValue?: ReactNode },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(30);
        return {
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { name, baId, id, hasOwn, emptyValue } = getData(row);
                const style = { color: isDefined(hasOwn) && !hasOwn ? '#cccccc' : undefined };

                return (
                    <div className={'rr-grid-text-cell-left'} title={name}>
                        {emptyValue ? (
                            <span style={{ color: '#aaaaaa' }}>{emptyValue}</span>
                        ) : id ? (
                            <Link to={`/${baId}/users/${id}`} style={style} onClick={(event) => event.stopPropagation()}>
                                {name}
                            </Link>
                        ) : (
                            <span style={style}>{name}</span>
                        )}
                    </div>
                );
            },
            ...column,
        };
    }

    static createCounterpartyColumn<T extends object>(
        getData: (row: T) => { baId?: number; id?: number; name?: string /*, hasOwn?: boolean, emptyValue?: ReactNode*/ },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(50);
        return {
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { name, baId, id } = getData(row);
                return (
                    <div className={'rr-grid-text-cell-left'} title={name}>
                        <Link to={`/${baId}/crm/counterparties/${id}`} onClick={(e) => e.stopPropagation()}>
                            {name}
                        </Link>
                    </div>
                );
            },
            ...column,
        };
    }

    static createProjectColumn<T extends object>(
        getData: (row: T) => { baId?: number; id?: number; name?: string /*, hasOwn?: boolean, emptyValue?: ReactNode*/ },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(50);
        return {
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { name, baId, id } = getData(row);
                return (
                    <div className={'rr-grid-text-cell-left'} title={name}>
                        <Link to={`/${baId}/projects/production/${id}`}>{name}</Link>
                    </div>
                );
            },
            ...column,
        };
    }

    static createActivityFrameColumn<T extends object>(
        getData: (row: T) => { baId?: number; id?: number; name?: string; activityFrameTypeCode: RentActivityFrameTypeCodeEnum },
        column?: TableColumn<T>,
        withIcon?: boolean
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(50);
        return {
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { name, baId, id, activityFrameTypeCode } = getData(row);
                let link: string | undefined;
                if (activityFrameTypeCode === RentActivityFrameTypeCodeEnum.PROJECT) link = `/${baId}/projects/production/${id}`;
                else if (activityFrameTypeCode === RentActivityFrameTypeCodeEnum.SUBRENT) link = `/${baId}/subrent/shippings/${id}`;
                return (
                    <div className={'rr-grid-text-cell-left'} title={name}>
                        {withIcon && (
                            <SystemIcon
                                type={
                                    activityFrameTypeCode === RentActivityFrameTypeCodeEnum.PROJECT
                                        ? SystemIconType.project
                                        : SystemIconType.subrent
                                }
                                style={{ verticalAlign: 'bottom', marginRight: 6 }}
                            />
                        )}
                        {link ? (
                            <Link to={link} onClick={(e) => e.stopPropagation()}>
                                {name}
                            </Link>
                        ) : (
                            name
                        )}
                    </div>
                );
            },
            ...column,
        };
    }

    static createProductVariantColumn<T extends object>(
        getData: (row: T) => { productId?: number; variantId?: number; variantName?: string; productName: string },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(30);
        return {
            title: 'Вариант',
            dataIndex: 'variantName' as any,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { variantName, productName, productId, variantId } = getData(row);
                return (
                    productId &&
                    variantId && (
                        <div
                            className={'rr-grid-shortName-cell rr-grid-text-cell-left'}
                            title={`${productName}: ${variantName}`}
                            style={{ fontWeight: 400 }}
                            onClick={(e) => e.stopPropagation()}
                        >
                            <ProductVariantPopover productId={productId} variantId={variantId}>
                                <span className={'app-link-underline'}>{variantName}</span>
                            </ProductVariantPopover>
                        </div>
                    )
                );
            },
            dontHide: false,
            showInMenuWhenExcluded: true,
            ...column,
        };
    }

    static createBarCodeColumn<T extends object>(
        getData: (row: T) => {
            nomenclatureType: NomenclatureEntityTypeCodeEnum;
            nomenclatureId: number;
            barcodeString?: string;
        },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(35);
        return {
            title: 'Штриховой код',
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            sortable: true,
            dontHide: false,
            render: (value: any, row: T) => {
                const data = getData(row);
                return data.barcodeString ? (
                    <div title={value} className={'rr-grid-text-cell-left'}>
                        <div
                            style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                            }}
                        >
                            <BarcodePrint
                                style={{
                                    marginRight: 10,
                                    fontSize: 22,
                                    verticalAlign: 'middle',
                                    marginTop: -2,
                                }}
                                nomenclatureType={data.nomenclatureType}
                                nomenclatureId={data.nomenclatureId}
                            />
                            {data.barcodeString}
                        </div>
                    </div>
                ) : null;
            },
            ...column,
        };
    }

    static createInventoryNumberColumn<T extends object>(): TableColumn<T> {
        const sizes = this.getColumnSizes(30);
        return {
            title: 'Инвентарный номер',
            dataIndex: 'inventoryNumber' as any,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: false,
            dontHide: false,
            render: (value: any, row: T) => {
                return (
                    <div title={value} className={'rr-grid-text-cell-left'}>
                        {value}
                    </div>
                );
            },
            defaultHidden: true,
        };
    }

    static createExternalCodeColumn<T extends object>(
        nomenclatureEntityType: NomenclatureEntityTypeCodeEnum,
        getData: (row: T) => { baId?: number; nomenclatureId?: number; code?: string; hasOwnCode?: boolean },
        column?: TableColumn<T>
    ): TableColumn<T> {
        const sizes = this.getColumnSizes(30);

        return {
            title: localize(LocalizationEnum.ASPECT__GRID__COLUMN__EXTERNAL_CODE),
            dataIndex: 'externalCode' as any,
            type: ColumnTypes.CustomRender,
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            resizable: true,
            render: (value: any, row: T) => {
                const { baId, nomenclatureId, code, hasOwnCode } = getData(row);
                let link: string | undefined;
                if (baId && nomenclatureId) {
                    if (nomenclatureEntityType === NomenclatureEntityTypeCodeEnum.PRODUCT)
                        link = `/${baId}/inventory/products/${nomenclatureId}`;
                    else if (nomenclatureEntityType === NomenclatureEntityTypeCodeEnum.KIT)
                        link = `/${baId}/inventory/kits/${nomenclatureId}`;
                }
                const style = { color: isDefined(hasOwnCode) && !hasOwnCode ? '#cccccc' : undefined };
                return code ? (
                    <div title={code} className={'rr-grid-text-cell-left'}>
                        {link ? (
                            <Link to={link} style={style}>
                                {code}
                            </Link>
                        ) : (
                            <span style={style}>{code}</span>
                        )}
                    </div>
                ) : null;
            },
            ...column,
        };
    }

    static createLastUpdateDateColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            //title: 'Дата посл. изменения',
            //dataIndex: 'lastUpdateDate',
            type: ColumnTypes.CustomRender,
            minWidth: 150,
            resizable: false,
            sortable: true,
            render: (value: Date) => {
                return value ? <DashboardDate value={value} days={7} /> : null;
            },
            ...column,
        };
    }

    static createUTCDateTimeColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            type: ColumnTypes.CustomRender,
            minWidth: 150,
            resizable: false,
            sortable: true,
            render: (value: Date) => {
                return moment.utc(value).format('DD.MM.YY HH:mm');
            },
            ...column,
        };
    }

    static createIconTextColumn<T extends object>(
        getData: (row: T) => { icon: ReactNode; text: ReactNode },
        column?: TableColumn<T>
    ): TableColumn<T> {
        return {
            type: ColumnTypes.CustomRender,
            minWidth: 150,
            resizable: false,
            sortable: true,
            render: (value: any, row: T) => {
                const { icon, text } = getData(row);
                return (
                    <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                        <span style={{ marginRight: 6, fontSize: 21, lineHeight: '21px' }}>{icon}</span>
                        {text}
                    </div>
                );
            },
            ...column,
        };
    }

    static createAttachmentsCountColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            type: ColumnTypes.CustomRender,
            minWidth: 132,
            resizable: false,
            sortable: true,
            render: (value: any, row: T) => {
                if (isDefined(value)) {
                    if (value === 0) {
                        return <span style={{ color: '#aaaaaa' }}>0</span>;
                    } else {
                        return (
                            <div style={{ display: 'inline-flex', alignItems: 'center', width: 'unset' }}>
                                <SystemIcon type={SystemIconType.attachment} style={{ marginRight: 6 }} />
                                {value}
                            </div>
                        );
                    }
                } else {
                    return null;
                }
            },
            ...column,
        };
    }

    static createReferenceCountColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            type: ColumnTypes.CustomRender,
            minWidth: 132,
            resizable: false,
            sortable: true,
            render: (value: any, row: T) => {
                if (isDefined(value)) {
                    if (value === 0) {
                        return <span style={{ color: '#aaaaaa' }}>0</span>;
                    } else {
                        return <div style={{ display: 'inline-flex', alignItems: 'center', width: 'unset' }}>{value}</div>;
                    }
                } else {
                    return null;
                }
            },
            ...column,
        };
    }

    static createActionsColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        return {
            title: <SystemIcon type={SystemIconType.actions} style={{ display: 'block' }} />,
            type: ColumnTypes.Actions,
            width: GRID_ACTIONS_COLUMN_WIDTH,
            maxWidth: GRID_ACTIONS_COLUMN_WIDTH,
            minWidth: GRID_ACTIONS_COLUMN_WIDTH,
            resizable: false,
            sortable: false,
            className: 'actions',
            headerClassName: 'actions',
            ...column,
        };
    }

    static createAddressColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        const sizes = this.getColumnSizes(50);
        return {
            title: 'Адрес',
            dataIndex: 'address' as any,
            type: ColumnTypes.String,
            resizable: true,
            className: 'locations-list_column-address', // ???
            minWidth: sizes.width,
            width: sizes.width,
            maxWidth: sizes.max,
            ...column,
        };
    }

    static createLocationColumn<T extends object>(column?: TableColumn<T>): TableColumn<T> {
        const sizes = this.getColumnSizes(70);
        return {
            //title: 'Адрес выгрузки',
            //dataIndex: 'unloadingLocationName',
            minWidth: sizes.min,
            width: sizes.width,
            maxWidth: sizes.max,
            type: ColumnTypes.Click,
            resizable: true,
            ...column,
        };
    }

    static createProjectReferenceCountColumn<T extends object>(
        getData: (row: T) => CreateProjectReferenceCountColumnGetDataProps,
        column?: TableColumn<T>
    ): TableColumn<T> {
        return {
            type: ColumnTypes.CustomRender,
            resizable: false,
            sortable: true,
            render: (value: any, row: T) => {
                const { count, baId, params } = getData(row);
                if (isDefined(count)) {
                    if (count === 0) {
                        return <span style={{ color: '#aaaaaa' }}>0</span>;
                    } else {
                        let paramsStr = '';
                        if (params) {
                            Object.keys(params).forEach((p, i, arr) => {
                                paramsStr += `${i === 0 ? '?' : ''}${p}=${params[p]}${i < arr.length - 1 ? '&' : ''}`;
                            });
                        }
                        return (
                            <Link onClick={(e) => e.stopPropagation()} to={`/${baId}/projects/production${paramsStr}`}>
                                {count}
                            </Link>
                        );
                    }
                } else {
                    return null;
                }
            },
            ...column,
        };
    }

    static getColumnSizes(maxLength: number) {
        let W = 15.64 * maxLength;
        return {
            min: Math.round(W / 6),
            width: Math.round(W / 3),
            max: Math.round(W),
        };
    }
}
