import React from 'react';
import { Icon } from 'antd';
import {
    IconBookSolid,
    IconBriefcase,
    IconCalendar,
    IconCalendarCheck,
    IconClose,
    IconCube,
    IconDollySolid,
    IconMoneyCheckAltSolid,
    IconPencil,
    IconScrollSolid,
    IconShapesSolid,
    IconStreamSolid,
} from '../icons';
import ActionsPopover from '../actionsPopover/ActionsPopover';
import { EntityActionType, getBusinessAccountId } from '../../../index';
import { HelpTooltip } from '../helpTooltip/HelpTooltip';
import { LocalizationEnum } from '../../localization';
import { Link } from 'react-router-dom';
import { CustomIconComponentProps } from 'antd/lib/icon';
import {
    CustomFieldRecord,
    CustomFieldWithValueObjRead,
    InstanceRecord,
    InstanceStateCodeEnum,
    PricingSchemeRecord,
    VariantInfoRead,
} from '../../server';
import { Styles } from '../../styles';
import { Status } from '../status/status';
import { linkToCalendar } from '../../shared/util/createLinkPath';
import { ElementsActionPopover } from '../../modules/main/elements/elements-action-popover';
import { BaseRecordData } from '../../modules/main/settings/pricingSchemes/pricingSchemePopover';
import { ArchiveBadge } from '../archiveBadge/archiveBadge';
import { PricingSchemeMaps } from '../../core/utils/pricingSchemeUtils';
import {getPathFromState, isDefined} from '../../shared/util/utils';
import { getGridStorageData } from '../grid/utils';
import { DateUtils } from '../../core/utils/dateUtils';
import moment from 'moment/moment';
import { AccessibilityMapFilters } from '../../modules/main/calendar/tabs/accessibilityMap/filters/accessibility/accessibilityMapCalendarFilters.types';
import { CalendarPageTabsEnum, HistoryPageTabsEnum } from '../../shared/constants/tabEnums';

interface IProps {
    closeCb: (key?: string) => void;
    closeElementPopup: () => void;
    onAction: (item: any, action: EntityActionType, instanceCount?, targetStateCode?) => void;
    title: string | React.ReactNode;
    businessAccountId: number;
    hideActionsButton?: boolean;
    hideCalendar?: boolean;
    projectIsSimpleOrder?: boolean;
}

interface IVariantProps extends IProps {
    entityType: 'variant';
    entity: any; //VariantRecord | NomenclatureRecord | VariantInfoRead;
}

interface ICustomFieldsProps extends IProps {
    entityType: 'customField';
    entity: CustomFieldRecord | CustomFieldWithValueObjRead;
}

interface IProductInstanceProps extends IProps {
    entityType: 'product-instances';
    entity: InstanceRecord;
}

interface IElementProps extends IProps {
    entityType: 'element';
    entity: any;
}

interface IPriceSchemeProps extends IProps {
    entityType: 'pricingScheme';
    entity: BaseRecordData;
}

const editIcon = () => (
    <div style={{ cursor: 'pointer' }}>
        <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__EDIT}>
            <Icon className={Styles.ICON_22} component={IconPencil} />
        </HelpTooltip>
    </div>
);

export class PopoverHeader extends React.PureComponent<
    IVariantProps | ICustomFieldsProps | IProductInstanceProps | IElementProps | IPriceSchemeProps
> {
    closePopups = (key?: string) => {
        this.props.closeCb(key);
        if (this.props.closeCb !== this.props.closeElementPopup) {
            this.props.closeElementPopup();
        }
    };

    renderProjectIcon = () => {
        let icon;
        let linkTo;
        let tooltip;

        if (this.props.entity.projectId) {
            if (!this.props.entity.projectIsOffer) {
                linkTo = `/${getBusinessAccountId()}/projects/production/${this.props.entity.projectId}`;
                icon = IconBriefcase;
                tooltip = LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_PROJECT;
            } else {
                linkTo = `/${getBusinessAccountId()}/projects/offers/${this.props.entity.projectId}`;
                icon = IconMoneyCheckAltSolid;
            }
            if(this.props.projectIsSimpleOrder){
                linkTo = `/${getBusinessAccountId()}/projects/simpleOrders/${this.props.entity.projectId}`;
                icon = IconMoneyCheckAltSolid;
            }
        } else if (this.props.entity.templateId) {
            linkTo = `/${getBusinessAccountId()}/projects/templates/${this.props.entity.templateId}`;
            icon = IconScrollSolid;
            tooltip = LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_PROJECT_TEMPLATE;
        } else if (this.props.entity.subrentId) {
            linkTo = `/${getBusinessAccountId()}/subrent/shippings/${this.props.entity.subrentId}`;
            icon = IconDollySolid;
            tooltip = LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__GO_TO_SHIPPING;
        }

        return (
            <HelpTooltip content={tooltip}>
                <Link style={{ marginRight: 10 }} to={linkTo} onClick={() => this.closePopups()}>
                    <Icon className={Styles.ICON_24} component={icon} />
                </Link>
            </HelpTooltip>
        );
    };

    renderHistoryIcon = () => {
        const gridData = getGridStorageData(HistoryPageTabsEnum.INVENTORY_MOVEMENTS);

        const variantProps = {
            productId: this.props.entity.productId,
            variantId: this.props.entity.id,
        };

        const instanceProps = {
            productId: this.props.entity.productId,
            instanceId: this.props.entity.id,
        };

        const currentProps =
            {
                variant: variantProps,
                'product-instances': instanceProps,
            }[this.props.entityType] ?? {};

        const linkTo = getPathFromState(`/${this.props.businessAccountId}/history/inventoryMovements`, '', {
            ...gridData.filters,
            ...gridData.params,
            sortBy: 'eventDate',
            sortOrder: 'DESC',
            ...currentProps,
        });

        return (
            <Link style={{ marginRight: 10 }} to={linkTo} onClick={() => this.closePopups()}>
                <Icon className={Styles.ICON_24} component={IconBookSolid} />
            </Link>
        );
    };

    render() {
        let { entityType, entity, title, onAction, closeCb } = this.props;
        let iconComponent: React.ComponentType<CustomIconComponentProps | React.SVGProps<SVGSVGElement>> | undefined;
        let archive;
        let stateCode;
        let productId;

        if (entityType === 'variant') {
            archive = (entity as VariantInfoRead).archive;
            productId = (entity as VariantInfoRead).productId;
            iconComponent = IconShapesSolid;
        } else if (entityType === 'product-instances') {
            stateCode = (entity as InstanceRecord).stateCode;
            productId = (entity as InstanceRecord).productId;
            iconComponent = IconCube;
        } else if (entityType === 'customField') {
            iconComponent = IconStreamSolid;
        } else if (entityType === 'element') {
            iconComponent = IconStreamSolid;
        } else if (entityType === 'pricingScheme') {
            iconComponent = PricingSchemeMaps.pricingSchemeTypeCodeToIconMap[(entity as PricingSchemeRecord).typeCode];
        }

        let paramsCalendar: {} = { productId: productId };

        const [screenLeft, screenRight] = DateUtils.getCalendarBoundaries(moment(Date.now()), 'month');

        const gridData = getGridStorageData(CalendarPageTabsEnum.ACCESSIBILITY_MAP);
        const { stacks } = gridData.filters as AccessibilityMapFilters;

        if (entityType === 'product-instances') {
            paramsCalendar = {
                ...paramsCalendar,
                variantId: entity.variantId,
                instanceId: entity.id,
                group: 'project',
                screenLeft,
                screenRight,
                stacks,
                hide: 'cancelled',
            };
        }

        if (entityType === 'variant') {
            paramsCalendar = {
                ...paramsCalendar,
                variantId: entity.id,
                instanceId: undefined,
                group: 'project',
                screenLeft,
                screenRight,
                stacks,
                hide: 'cancelled',
            };
        }

        if (entityType === 'element') {
            paramsCalendar = {
                ...paramsCalendar,
                elementId: entity.id,
                kit: entity.rentElementsKitId,
                group: 'project',
                projectId: entity.projectId,
                subrentId: entity.subrentId,
                renterId: entity.renterId,
                limit: '1000',
                capture: 'all',
            };
        }

        const tab = entityType === 'variant' || entityType === 'product-instances' ? CalendarPageTabsEnum.ACCESSIBILITY_MAP : undefined;
        const calendarIcon = entityType === 'variant' || entityType === 'product-instances' ? IconCalendarCheck : IconCalendar;

        let showCalendar = entityType !== 'customField' && entityType !== 'pricingScheme';
        if (entityType === 'element' && !entity.projectId) showCalendar = false;
        if (entityType === 'element' && entity.subrentId) showCalendar = true;

        if(isDefined(this.props.hideCalendar)) showCalendar = !this.props.hideCalendar;

        return (
            <div className={'rr-grid-actions-popover-header'} style={{ display: 'flex' }}>
                {entityType !== 'element' ? (
                    <>
                        {iconComponent ? (
                            <>
                                <Icon style={{ marginLeft: 4, marginRight: 10 }} className={Styles.ICON_26} component={iconComponent} />{' '}
                            </>
                        ) : null}
                        <span style={{ marginRight: 22, fontSize: 18, whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {title}
                        </span>
                    </>
                ) : null}
                {archive ? <ArchiveBadge style={{ marginRight: 10 }} /> : null}
                {stateCode ? (
                    <span className="actions-popover-header-status">
                        <Status
                            entityType={entityType}
                            code={stateCode === InstanceStateCodeEnum.UNDERMAINTENANCE ? 'UNDER_MAINTENANCE_BORDERED' : stateCode}
                        />
                    </span>
                ) : null}
                <div style={{ flex: '1 1 auto' }}></div>
                {(entityType === 'variant' || entityType === 'product-instances') && this.renderHistoryIcon()}
                {entityType === 'element' && this.renderProjectIcon()}
                {showCalendar ? (
                    <Link style={{ marginRight: 10 }} to={linkToCalendar(paramsCalendar, tab)} onClick={() => this.closePopups('calendar')}>
                        <Icon className={Styles.ICON_24} component={calendarIcon} />
                    </Link>
                ) : null}
                {!this.props.hideActionsButton ? (
                    entityType === 'element' ? (
                        <ElementsActionPopover disabled={false} record={this.props.entity} onAction={onAction}>
                            {editIcon}
                        </ElementsActionPopover>
                    ) : (
                        <ActionsPopover entity={entity} onAction={onAction} entityType={entityType}>
                            {editIcon}
                        </ActionsPopover>
                    )
                ) : null}
                <HelpTooltip content={LocalizationEnum.PAGE__OPERATIONS__POPUP__TOOLTIP__CLOSE}>
                    <Icon style={{ marginLeft: 10 }} className={Styles.ICON_22} component={IconClose} onClick={() => closeCb()} />
                </HelpTooltip>
            </div>
        );
    }
}
