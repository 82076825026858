import { getAvailableGridSortById } from '../../../../../shared/util/getAvailableGridSortById';
import projectColumns from '../list/simpleOrdersColumns';
import { projectsInitialParamsState } from '../reducers/simpleOrders.reducer';

export const getProjectsSortBy = (params: typeof projectsInitialParamsState) => {
    return getAvailableGridSortById(
        params.sortBy === 'problemSeverity' ? 'problemsAndWarnings.severity' : params.sortBy,
        projectColumns,
        ['problemsAndWarnings.severity'],
        projectsInitialParamsState.sortBy
    );
};
