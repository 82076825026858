import { FAILURE, REQUEST, SUCCESS } from '../../../../../shared/reducers/action-type.util';
import { ProjectRecord, ProjectRecordList, serverApi, ServerError, WorkflowDirective } from '../../../../../server';
import { showNotification } from '../../../../../components/notification/showNotification';
import { IRootState } from '../../../../../shared/reducers';
import { getDatesFromParamsString, getServerError, getStateFromPath2, test1 } from '../../../../../shared/util/utils';
import { LOCATION_CHANGE } from 'connected-react-router';
import { LocalizationEnum, localizeIntl } from '../../../../../localization';
import { gridDataChangedSignal } from '../../../../../components/controllers/scroll/SelectScrollController/SelectScrollController';
import { getProjectsFilters } from '../utils/getProjectsFilters';
import { getProjectsSortBy } from '../utils/getProjectsSortBy';

export const ACTION_TYPES = {
    LOAD_ENTITIES: 'simpleOrders/LOAD_ENTITIES',
    RESET: 'simpleOrders/RESET',
    RESET_PROJECT: 'simpleOrders/RESET_PROJECT',
    CHANGE_ARCHIVE: 'simpleOrders/CHANGE_ARCHIVE',
    CHANGE_STATUS: 'simpleOrders/CHANGE_STATUS',
};

export const projectsInitialParamsState = {
    search: undefined as string | undefined,
    sortBy: 'lastActivityDate' as string | undefined,
    sortOrder: 'DESC' as 'ASC' | 'DESC' | undefined,
    status: undefined as string | undefined,
    page: 1,
    limit: 10,
    hide: undefined as 'archive' | 'cancelled' | 'archive+cancelled' | undefined,
    shortage: undefined as boolean | undefined,
    delay: undefined as boolean | undefined,
    renterId: undefined as string | undefined,
    startDate: [undefined, undefined] as (number | undefined)[] | undefined,
    problem: undefined as string | undefined,
    sourceCode: undefined as string | undefined,
    locationId: undefined as string | undefined,
    tags: undefined as string[] | undefined,
    assigneeId: undefined as string | undefined,
    debtSum: undefined as number[] | undefined,
};

export enum ProjectsHideState {
    Archive = 'archive',
    Cancelled = 'cancelled',
    ArchiveCancelled = 'archive+cancelled',
}

const initialState = {
    loading: false,
    loadingError: undefined as undefined | ServerError,
    entities: null as Array<ProjectRecord> | null,
    filteredCount: 0,
    smartFiltersOpened: false,
    params: {
        ...projectsInitialParamsState,
    },
};

export type SimpleOrdersState = Readonly<typeof initialState>;

export default (state: SimpleOrdersState = initialState, action): SimpleOrdersState => {
    switch (action.type) {
        case LOCATION_CHANGE:
            let pathName =
                action.payload && action.payload.location && action.payload.location.pathname ? action.payload.location.pathname : '';
            let params = getStateFromPath2(action.payload.location.search);

            if (
                /\/\d+\/projects\/simpleOrders\/?/.test(pathName) ||
                (/\/\d+\/crm\/counterparties\/(\d+)/.test(pathName) && (params.tab === 'simpleOrders')) //  || !params.tab
            ) {
                let arr = /\/\d+\/crm\/counterparties\/(\d+)/.exec(pathName);
                if (arr && arr[1]) {
                    params.renterId = +arr[1];
                }

                return {
                    ...state,
                    params: {
                        ...state.params,
                        search: params.search !== undefined ? '' + params.search : projectsInitialParamsState.search,
                        sortBy: params.sortBy !== undefined ? params.sortBy : projectsInitialParamsState.sortBy,
                        sortOrder: params.sortOrder !== undefined ? params.sortOrder : projectsInitialParamsState.sortOrder,
                        status: params.status !== undefined ? params.status : projectsInitialParamsState.status,
                        page: params.page !== undefined ? +params.page : projectsInitialParamsState.page,
                        limit: params.limit !== undefined ? +params.limit : projectsInitialParamsState.limit,
                        hide: params.hide !== undefined ? params.hide : projectsInitialParamsState.hide,
                        shortage: params.shortage !== undefined ? params.shortage : projectsInitialParamsState.shortage,
                        delay: params.delay !== undefined ? params.delay : projectsInitialParamsState.delay,
                        renterId: params.renterId !== undefined ? params.renterId : projectsInitialParamsState.renterId,
                        startDate: getDatesFromParamsString(params.startDate, projectsInitialParamsState.startDate),
                        problem: params.problem !== undefined ? '' + params.problem : projectsInitialParamsState.problem,
                        sourceCode: params.sourceCode !== undefined ? params.sourceCode : projectsInitialParamsState.sourceCode,
                        locationId: params.locationId !== undefined ? params.locationId : projectsInitialParamsState.locationId,
                        tags: params.tags !== undefined ? String(params.tags).split(',') : projectsInitialParamsState.tags,
                        assigneeId: params.assigneeId !== undefined ? params.assigneeId : projectsInitialParamsState.assigneeId,
                        debtSum: test1(params.debtSum, projectsInitialParamsState.debtSum)?.map((price) => Math.round(price)),
                    },
                };
            }
            return state;

        case REQUEST(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_ARCHIVE):
            return {
                ...state,
                loading: true,
            };

        case REQUEST(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.CHANGE_STATUS):
            return {
                ...state,
                loading: true,
            };

        case REQUEST(ACTION_TYPES.LOAD_ENTITIES):
            return {
                ...state,
                loadingError: undefined,
                loading: true,
            };
        case FAILURE(ACTION_TYPES.LOAD_ENTITIES):
            console.dir(action.payload);
            return {
                ...state,
                loadingError: getServerError(action.payload),
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.LOAD_ENTITIES):
            gridDataChangedSignal();

            return {
                ...state,
                loading: false,
                entities: (action.payload.data as ProjectRecordList).records,
                filteredCount: (action.payload.data as ProjectRecordList).listAttributes.filteredCount,
            };
        case ACTION_TYPES.RESET:
            return {
                ...initialState,
            };
        case ACTION_TYPES.RESET_PROJECT:
            return {
                ...state,
                entities: null,
            };

        default:
            return state;
    }
};

export const loadProjects = (intl, businessAccountId: number) => {
    return (dispatch, getState) => {
        const state: SimpleOrdersState = (getState() as IRootState).simpleOrders;
        const { params } = state;

        const filters = getProjectsFilters(params);

        const sortBy = getProjectsSortBy(params);

        return dispatch({
            type: ACTION_TYPES.LOAD_ENTITIES,
            payload: serverApi.listProjects(
                businessAccountId,
                params.limit,
                (params.page - 1) * params.limit,
                undefined,
                sortBy,
                params.sortOrder,
                params.search,
                { query: { filters } }
            ),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', 'Не удалось загрузить список заказов');
            }
        });
    };
};

export const setArchiveState = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_ARCHIVE,
            payload: serverApi.transitProjectWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                showNotification(
                    'success',
                    directives.length > 1
                        ? 'Заказы изменены'
                        : 'Заказ изменен'
                );
                setTimeout(() => dispatch(loadProjects(intl, businessAccountId)), 1500);
            }
        });
    };
};

export const setStatus = (intl, businessAccountId: number, directives: Array<WorkflowDirective>) => {
    return (dispatch) => {
        return dispatch({
            type: ACTION_TYPES.CHANGE_STATUS,
            payload: serverApi.transitProjectWorkflowBulk(businessAccountId, { directives: directives }),
        }).then((result) => {
            if (result instanceof Error) {
                showNotification('error', localizeIntl(intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
            } else {
                showNotification(
                    'success',
                    directives.length === 1
                        ? 'Статус заказа изменен'
                        : 'Статус заказов изменен'
                );
                setTimeout(() => dispatch(loadProjects(intl, businessAccountId)), 1500);
            }
        });
    };
};

export const reset = () => ({
    type: ACTION_TYPES.RESET,
});

export const resetProject = () => ({
    type: ACTION_TYPES.RESET_PROJECT,
});
