import React from "react";
import {DatePickerModified, DatePickerModifiedProps} from "./DatePickerModified";
import {TodayButton} from "../v2/calendar/rangePicker/components/todayButton/todayButton";
import moment from "moment";
import {getCurrentServerTime} from "../../shared/reducers/system.reducer";

interface DatePickerProps extends DatePickerModifiedProps {
    align?: { points?: string[], offset?: number[] };
}

export class DatePicker extends React.PureComponent<DatePickerProps> {

    render() {
        return (
            <DatePickerModified
                {...this.props}
                allowClear={this.props.allowClear}
                showToday={false}
                renderExtraFooter={()=>(
                    <TodayButton
                        onClick={()=>{
                            const today = moment(getCurrentServerTime()).startOf('day');
                            this.props.onChange?.(today, '');
                        }}
                        style={{position: "absolute", zIndex: 11, top: 20.5, right: 68}}
                    />
                )}
                {...this.props.align ? {align: this.props.align} : {align: {points: ['bl', 't'], offset: [0, -6]}}}
            />
        );
    }
}
