import React from 'react';
import { Alert, Button, Icon, Tabs, Tooltip } from 'antd';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router';
import { push, replace } from 'connected-react-router';
import { loadEntity, reset, setStatusAndArchive, updateEntity } from '../reducers/simpleOrder.reducer';
import { IRootState } from '../../../../../shared/reducers';
import { RoundButton, Spin } from '../../../../../components';
import {
    IconArchive,
    IconBorderStyleSolid,
    IconControlPointDuplicateBlack,
    IconEdit,
    IconEraser,
    IconFlagCheckered,
    IconLevelUp,
    IconPlus,
    IconReply,
    IconRocket,
    IconScrollSolid,
} from '../../../../../components/icons';
import { SimpleOrderCreateModal } from '../modal/simpleOrderCreateModal';
import { ProjectPageDescriptionTabPane } from './tabs/description/project-page-description';
import { showConfirm } from '../../../../../components/confirm/showConfirm';
import { getPathFromState, getShiftCountFromDates, getStateFromPath, isStringInEnum } from '../../../../../shared/util/utils';
import { BasePage, IBasePageProps } from '../../../../../components/page/BasePage';
import { IPageHeaderProps } from '../../../../../components/pageHeader/pageHeader';
import { OperationsList } from '../../../operations/operationsList';
import { ElementsList } from '../../../elements/elements-list';
import getParamsLink from '../../../../../shared/util/getParamsLink';
import {
    fullResetOperation,
    removeConcurrentOperation,
    resetOperationForm,
    setRenterAndProject,
    startNewOperation,
} from '../../../operationForm/reducers/operationForm.reducer';
import { logEvent } from '../../../../../analytics/analytics';
import {
    OperationTypeCodeEnum,
    ProjectStateCodeEnum,
    ProjectTransitionCodeEnum,
    ProjectTypeCodeEnum,
    RentActivityFrameTypeCodeEnum,
    RenterStateCodeEnum,
    RentStateCodeEnum,
} from '../../../../../server';
import { LocalizationEnum, localize, localizeIntl } from '../../../../../localization';
import AdditionalEntityActions from '../../../../../components/additionalEntityActions/additionalEntityActions';
import { AddProjectPopover1 } from './add-project-popover1';
import { loadEntities } from '../../../elements/reducers/elements.reducer';
import { AdditionalEntityActionsButton } from '../../../../../components/additionalEntityActions/additionalEntityActionsButton';
import { OperationTypeSelectButton } from './OperationTypeSelectButton';
import { showActualizeDatesConfirm } from '../../../../../shared/util/showActualizeDatesConfirm';
import moment from 'moment';
import { getCurrentServerTime } from '../../../../../shared/reducers/system.reducer';
import { Moment } from 'moment/moment';
import { canAddNewProductsToOperation } from '../../../operationForm/utils/utils';
import { canUpdateFinancialData, canViewFinancialData } from '../../../../../shared/util/permissionUtils';
import { SystemIcon, SystemIconType } from '../../../../../components/v2/systemIcon/systemIcon';
import { PaymentCreateModal } from '../../../payments/components/modal/paymentCreateModal';
import { listConcurrentOperationsAndShowConfirm } from '../../../operationForm/listConcurrentOperationsAndShowConfirm';
import { createSimpleOrderModalFilters, editSimpleOrderModalFilters } from '../modal/projectsPageData';
import { getCurrentTabsSelector, saveCurrentTabToStore } from '../../../../../shared/reducers/userSettings/userSettings.reducer';
import { TransportationsList } from '../../../logistics/tabs/transportations/list/TransportationsList';
import {
    crewModuleEnabledSelector,
    expensesModuleEnabledSelector,
    logisticsModuleEnabledSelector,
    offersModuleEnabledSelector,
    statisticsModuleEnabledSelector,
} from '../../../../../shared/reducers/businessAccountPreferences.reducer';
import { ExpensesList } from '../../../expenses/list/expensesList';
import { getGridStorageData } from '../../../../../components/grid/utils';
import { ParamsUtils } from '../../../../../core/utils/paramsUtils';
import { WorkPlanningsList } from '../../../workPlannings/list/workPlanningsList';
import { ReportReferenceTypeEnum } from '../../../../../components/pageHeader/ReportSelectDropdown';
import { OfferCreateModalWrapper } from '../../../offers/modal/create/offerCreateModalWrapper';
import { projectsPageUrlRoute } from '../../projectsPage';
import { ProjectOffersTab } from './tabs/offers/projectOffersTab';
import { LinkToCalendar } from '../../../../../components/link/toCalendar/LinkToCalendar';
import { createNewDraftOperation } from '../../../elements/create-operation-util';
import { showNotification } from '../../../../../components/notification/showNotification';

import { CalendarPageTabsEnum, ProjectPageTabsEnum, ProjectsPageTabsEnum } from '../../../../../shared/constants/tabEnums';
import { ProjectPageHeaderNavigationBlock } from '../components/HeaderNavigationBlock/ProjectPageHeaderNavigationBlock';

interface IState {
    operationIsLoading: boolean;
    selectTemplatePopoverOpened: boolean;
}

interface IProps extends StateProps, DispatchProps, IBasePageProps {}

const defaultTab = ProjectPageTabsEnum.ELEMENTS;

export enum ProjectPageGridNamesEnum {
    OFFERS = 'project-offers',
    OPERATIONS = 'project-operations',
    ELEMENTS = 'project-elements',
    EXPENSES = 'project-expenses',
    TRANSPORTATIONS = 'project-transportations',
    WORK_PLANNINGS = 'project-workPlannings',
}

export const projectGridNamesMap: Record<
    Exclude<ProjectPageTabsEnum, ProjectPageTabsEnum.DESCRIPTION | ProjectPageTabsEnum.STATISTICS>,
    ProjectPageGridNamesEnum
> = {
    [ProjectPageTabsEnum.OFFERS]: ProjectPageGridNamesEnum.OFFERS,
    [ProjectPageTabsEnum.OPERATIONS]: ProjectPageGridNamesEnum.OPERATIONS,
    [ProjectPageTabsEnum.ELEMENTS]: ProjectPageGridNamesEnum.ELEMENTS,
    [ProjectPageTabsEnum.EXPENSES]: ProjectPageGridNamesEnum.EXPENSES,
    [ProjectPageTabsEnum.TRANSPORTATIONS]: ProjectPageGridNamesEnum.TRANSPORTATIONS,
    [ProjectPageTabsEnum.WORK_PLANNINGS]: ProjectPageGridNamesEnum.WORK_PLANNINGS,
};

class SimpleOrderPage extends BasePage<IProps, IState> {
    private additionalEntityActionsRef;

    constructor(props: IProps) {
        super(props);
        this.props.resetOperationForm();

        this.state = {
            operationIsLoading: false,
            selectTemplatePopoverOpened: false,
        };
    }

    async componentDidMount() {
        super.componentDidMount();

        await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        this.logViewEvent();
    }

    async componentDidUpdate(prevProps: IProps) {
        if (this.props.tab) saveCurrentTabToStore('project', this.props.tab);

        if (prevProps.entityId !== this.props.entityId) {
            this.props.reset();
            await this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
        }
    }

    componentWillUnmount() {
        super.componentWillUnmount();
        this.props.reset();
    }

    onTabsChange = (key: string) => {
        const tabParams = this.getTabParams(key as ProjectPageTabsEnum);

        saveCurrentTabToStore('project', key);

        if (
            key === ProjectPageTabsEnum.OFFERS ||
            key === ProjectPageTabsEnum.EXPENSES ||
            key === ProjectPageTabsEnum.TRANSPORTATIONS ||
            key === ProjectPageTabsEnum.WORK_PLANNINGS
        ) {
            let gridName = projectGridNamesMap[key];
            if (gridName) {
                let gridData = getGridStorageData(gridName);
                this.props.push(
                    getPathFromState(this.props.location.pathname, '', {
                        tab: key,
                        ...gridData.filters,
                        ...gridData.params,
                    })
                );
            }
        } else {
            this.props.push(getPathFromState(this.props.location.pathname, '', { ...tabParams, tab: key }));
        }
        this.logViewEvent();
    };

    onEditButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}/edit?tab=description`);
    };

    onArchiveButtonClick = () => {
        let { entity } = this.props;
        if (entity) {
            if (!entity.archive) {
                //TODO Нормальный текст предупреждения придумать
                showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__PROJECTS__MODAL__QUESTIONS__SEND_TO_ARCHIVE),
                    undefined,
                    () => {
                        if (entity)
                            this.props.setStatusAndArchive(
                                this.props.intl,
                                this.props.businessAccountId,
                                this.props.entityId,
                                entity.businessVersion,
                                undefined,
                                true
                            );
                    }
                );
            } else {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    entity.businessVersion,
                    undefined,
                    false
                );
            }
        }
    };

    onChangeStatusClick = async () => {
        let { entity } = this.props,
            transitionCode,
            title,
            businessVersion;

        if (entity) {
            businessVersion = entity.businessVersion;

            if (entity.stateCode === 'NEW') {
                transitionCode = 'ACTIVATE';
            } else if (entity.stateCode === 'IN_PROGRESS') {
                transitionCode = 'FINISH';
                title = localizeIntl(this.props.intl, LocalizationEnum.PAGE__PROJECTS__MODAL__QUESTIONS__FINISH);
            } else if (entity.stateCode === 'FINISHED') {
                transitionCode = 'ACTIVATE';
            } else if (entity.stateCode === ProjectStateCodeEnum.CANCELED) {
                transitionCode = 'ACTIVATE';
            }

            let yes = true;
            if (transitionCode !== 'ACTIVATE') {
                yes = await showConfirm(this.props.intl, title);
            }
            if (yes) {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    businessVersion,
                    transitionCode
                );
            }
        }
    };

    onCancelButtonClick = async () => {
        const actionAuthorized = await listConcurrentOperationsAndShowConfirm(
            this.props.intl,
            this.props.businessAccountId,
            this.props.entityId,
            RentActivityFrameTypeCodeEnum.PROJECT,
            false
        );
        if (!actionAuthorized) return;

        let { entity } = this.props;
        if (entity) {
            let ok = await showConfirm(this.props.intl, 'Отменить проект?', 'При отмене проекта все его обязательства будут отменены');
            if (ok) {
                this.props.setStatusAndArchive(
                    this.props.intl,
                    this.props.businessAccountId,
                    this.props.entityId,
                    entity.businessVersion,
                    'CANCEL'
                );
            }
        }
    };

    onCopyButtonClick = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}/copy?tab=description`);
    };

    createNewOperation = async (code: OperationTypeCodeEnum, correctionCode?: RentStateCodeEnum | undefined) => {
        if (this.props.entity) {
            let canCreate = true;
            if (this.props.operationFormMnemoKey) {
                canCreate = await showConfirm(
                    this.props.intl,
                    localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
                );
            }

            if (canCreate) {
                try {
                    canCreate = await listConcurrentOperationsAndShowConfirm(
                        this.props.intl,
                        this.props.businessAccountId,
                        this.props.entity.id,
                        RentActivityFrameTypeCodeEnum.PROJECT
                    );
                } catch (e) {
                    return;
                }
            }

            if (canCreate) {
                let defaultStartDate: Moment = moment(getCurrentServerTime())/*.seconds(0)*/.milliseconds(0);
                let setActualDateRes: { setActualDate: boolean; recalculateShiftCount: boolean } = {
                    setActualDate: false,
                    recalculateShiftCount: false,
                };
                let startD: Moment | undefined;
                let endD: Moment | undefined;
                let discount = this.props.entity.defaultDiscount;
                let shiftCount: number | undefined = undefined;

                if (this.props.entity.startDate && this.props.entity.targetFinishDate) {
                    startD = moment(this.props.entity.startDate).seconds(0).milliseconds(0);
                    endD = moment(this.props.entity.targetFinishDate).seconds(0).milliseconds(0);
                } else {
                    startD = moment(defaultStartDate);
                    endD = moment(defaultStartDate).add(7, 'd');
                }

                if (code === OperationTypeCodeEnum.RENT) {
                    setActualDateRes = await showActualizeDatesConfirm(this.props.intl, 'Установить текущую дату выдачи?', 'rent');
                    if (setActualDateRes.setActualDate) {
                        startD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                } else if (code === OperationTypeCodeEnum.RETURN) {
                    setActualDateRes = await showActualizeDatesConfirm(this.props.intl, 'Установить текущую дату возврата?', 'return');
                    if (setActualDateRes.setActualDate) {
                        endD = moment(defaultStartDate);
                        shiftCount = setActualDateRes.recalculateShiftCount
                            ? getShiftCountFromDates(
                                  startD,
                                  endD,
                                  this.props.defaultShiftLengthInMinutes,
                                  this.props.shiftCountRoundingType
                              )
                            : shiftCount;
                    }
                }

                await this.props.fullResetOperation();

                this.props.startNewOperation(
                    undefined,
                    this.props.entity.taxRate,
                    this.props.entity.taxBaseType,
                    false,
                    this.props.entity.renterId,
                    this.props.entity.renterShortName,
                    this.props.entity.id,
                    this.props.entity.shortName,
                    undefined,
                    code,
                    [],
                    'mnemoKey',
                    false,
                    discount,
                    correctionCode,
                    undefined,
                    undefined,
                    [],
                    {},
                    setActualDateRes.setActualDate,
                    setActualDateRes.recalculateShiftCount,
                    shiftCount,
                    startD?.toDate(),
                    endD?.toDate(),
                    defaultStartDate.toDate()
                );
                if (canAddNewProductsToOperation(code)) {
                    this.props.push(`/${this.props.businessAccountId}/operation?tab=products`);
                } else {
                    this.props.push(`/${this.props.businessAccountId}/operation?tab=equipment`);
                }
            }
        }
    };

    createTransportation = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);

        searchParams.append('modal', 'new');
        searchParams.set('tab', ProjectPageTabsEnum.TRANSPORTATIONS);

        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';

        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}${paramsString}`);
    };

    createExpense = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        searchParams.set('tab', ProjectPageTabsEnum.EXPENSES);
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}${paramsString}`);
    };

    createWorkPlanning = () => {
        const urlSearchParams = ParamsUtils.getURLSearchParams(this.props.location);
        const searchParams = new URLSearchParams(urlSearchParams);
        searchParams.append('modal', 'new');
        searchParams.set('tab', ProjectPageTabsEnum.WORK_PLANNINGS);
        const paramsString = searchParams.toString() ? `?${searchParams.toString()}` : '';
        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}${paramsString}`);
    };

    createOffer = () => {
        this.props.push(
            `/${this.props.businessAccountId}/projects/production/${this.props.entityId}/newOffer${this.props.location.search}`
        );
    };

    onCreateDraftButtonClick = async () => {
        let canCreate = true;

        if (this.props.operationFormMnemoKey) {
            canCreate = await showConfirm(
                this.props.intl,
                localizeIntl(this.props.intl, LocalizationEnum.PAGE__OPERATIONS__MODAL__QUESTIONS__CANCEL)
            );
        }
        if (canCreate && this.props.entity) {
            this.setState({ operationIsLoading: true });
            try {
                await createNewDraftOperation(
                    OperationTypeCodeEnum.DRAFT,
                    false,
                    this.props.startNewOperation,
                    this.props.businessAccountId,
                    this.props.entity.id,
                    this.props.entity.shortName,
                    this.props.entity.renterId,
                    this.props.entity.renterShortName
                );
                this.props.push(`/${this.props.businessAccountId}/operation?tab=${'products'}`);
                this.setState({ operationIsLoading: false });
            } catch (e) {
                showNotification('error', localizeIntl(this.props.intl, LocalizationEnum.ASPECT__GLOBAL__ERROR));
                this.setState({ operationIsLoading: false });
            }
        } else {
            this.setState({ operationIsLoading: false });
        }
    };

    onAddFromTemplateButtonClick = (e) => {
        e.stopPropagation();
    };

    onPay = () => {
        this.props.push(`/${this.props.businessAccountId}/projects/production/${this.props.entityId}/pay/?tab=description`);
    };

    getPageHeaderProps(): IPageHeaderProps {
        let { entity, statisticsModuleEnabled } = this.props;

        return {
            archive: !!(entity && entity.archive),
            name: entity && entity.fullName ? entity.fullName : entity?.shortName,
            shortName: !(entity && entity.fullName),
            businessAccountId: this.props.businessAccountId,
            rightBlock: entity ? (
                <LinkToCalendar
                    calendarPath={`/${this.props.businessAccountId}/calendar${getParamsLink(
                        entity,
                        'project'
                    )}&group=project&capture=all&tab=${CalendarPageTabsEnum.ELEMENTS}`}
                />
            ) : undefined,
            parentPagePath: entity ? `/${this.props.businessAccountId}/projects/production` : undefined,
            reportReferenceType: statisticsModuleEnabled ? ReportReferenceTypeEnum.PROJECT : undefined,
            reportReferenceEntityId: statisticsModuleEnabled ? this.props.entityId : undefined,
            NavigationBlock: <ProjectPageHeaderNavigationBlock project={entity || undefined} />,
        };
    }

    logViewEvent = () => {
        logEvent({ type: 'view project', data: { 'entity id': this.props.entityId, tab: this.props.tab } });
    };

    getTabParams(tab: ProjectPageTabsEnum) {
        let gridName = projectGridNamesMap[tab];
        if (gridName) {
            const storageData = getGridStorageData(gridName);
            return {
                ...storageData.filters,
                ...storageData.params,
            };
        } else {
            return {};
        }
    }

    get currentTab() {
        return new URLSearchParams(this.props.location.search).get('tab');
    }

    renderContent() {
        let {
            entity,
            loading,
            updating,
            canViewFinancialData,
            canUpdateFinancialData,
            logisticsModuleEnabled,
            crewModuleEnabled,
            offersModuleEnabled,
            expensesModuleEnabled,
        } = this.props;
        const { currentTab } = this;

        if (currentTab == null) {
            const tab = isStringInEnum(this.props.tabFromStore, ProjectPageTabsEnum, defaultTab);
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(tab),
                        tab: tab,
                    })}
                />
            );
        }

        /**
         * Когда переходим в раздел из меню и вкладка не выбрана
         * ИЛИ Когда открываем урл и там вкладка прописана:
         * Если модуль отключен и вкладку прячем, то делаем редирект на вкладку по умолчанию
         */
        if (
            (currentTab == null &&
                ((this.props.tabFromStore === ProjectPageTabsEnum.EXPENSES && !expensesModuleEnabled) ||
                    (this.props.tabFromStore === ProjectPageTabsEnum.TRANSPORTATIONS && !logisticsModuleEnabled) ||
                    (this.props.tabFromStore === ProjectPageTabsEnum.OFFERS && !offersModuleEnabled) ||
                    (this.props.tabFromStore === ProjectPageTabsEnum.WORK_PLANNINGS && !crewModuleEnabled))) ||
            (currentTab != null &&
                ((currentTab === ProjectPageTabsEnum.EXPENSES && !expensesModuleEnabled) ||
                    (currentTab === ProjectPageTabsEnum.TRANSPORTATIONS && !logisticsModuleEnabled) ||
                    (currentTab === ProjectPageTabsEnum.OFFERS && !offersModuleEnabled) ||
                    (currentTab === ProjectPageTabsEnum.WORK_PLANNINGS && !crewModuleEnabled)))
        ) {
            return (
                <Redirect
                    to={getPathFromState(this.props.location.pathname, '', {
                        ...this.getTabParams(defaultTab),
                        tab: defaultTab,
                    })}
                />
            );
        }

        // Когда нет параметров в урле и есть сохраненные данные для грида, то редиректим
        if (
            currentTab === ProjectPageTabsEnum.OFFERS ||
            currentTab === ProjectPageTabsEnum.EXPENSES ||
            currentTab === ProjectPageTabsEnum.TRANSPORTATIONS ||
            currentTab === ProjectPageTabsEnum.WORK_PLANNINGS
        ) {
            if (
                Array.from(new URLSearchParams(this.props.location.search).keys()).filter((p) => !['tab', 'modal'].includes(p)).length === 0
            ) {
                let gridName = projectGridNamesMap[currentTab];
                if (gridName) {
                    const gridData = getGridStorageData(gridName);
                    if (
                        (gridData.filters && Object.keys(gridData.filters).filter((k) => k !== 'tab' && k !== 'modal').length > 0) ||
                        (gridData.params && Object.keys(gridData.params).length > 0)
                    ) {
                        const redirectPath = getPathFromState(this.props.location.pathname, this.props.location.search, {
                            tab: currentTab,
                            ...gridData.filters,
                            ...gridData.params,
                        });
                        return <Redirect to={redirectPath} />;
                    }
                }
            }
        }

        let err;
        if (this.props.loadingError) {
            err = (
                <>
                    <div>
                        <strong>{this.props.loadingError.title}</strong>
                    </div>
                    <div>{this.props.loadingError.message}</div>
                </>
            );
        }

        if (entity && entity.projectType === ProjectTypeCodeEnum.OFFER) {
            return <Redirect to={`/${this.props.businessAccountId}/${projectsPageUrlRoute}/${ProjectsPageTabsEnum.OFFERS}/${entity.id}`} />;
        }

        const isProjectCancelled = entity?.stateCode === ProjectStateCodeEnum.CANCELED;
        const isRenterInactive =
            entity?.renterStateCode === RenterStateCodeEnum.NEW || entity?.renterStateCode === RenterStateCodeEnum.BLOCKED;

        const isActivateActionAvailable =
            entity?.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.ACTIVATE) || !isRenterInactive;

        return (
            <>
                <Spin spinning={loading || updating || this.state.operationIsLoading}>
                    <div className={'rr-page-renter'} style={{ minHeight: 400 }}>
                        {err ? (
                            <Alert
                                message={localize(LocalizationEnum.ASPECT__GLOBAL__ERROR)}
                                description={err}
                                type="error"
                                showIcon
                                closable
                                style={{ marginBottom: 30 }}
                            />
                        ) : null}

                        {entity ? (
                            <div className="card-container">
                                <Tabs
                                    defaultActiveKey={'description'}
                                    activeKey={this.props.tab}
                                    onChange={this.onTabsChange}
                                    className={'rr-tabs'}
                                    type="line"
                                    animated={false}
                                    destroyInactiveTabPane
                                    tabBarExtraContent={
                                        <>
                                            {this.props.tab !== ProjectPageTabsEnum.TRANSPORTATIONS &&
                                                this.props.tab !== ProjectPageTabsEnum.EXPENSES &&
                                                this.props.tab !== ProjectPageTabsEnum.OFFERS &&
                                                this.props.tab !== ProjectPageTabsEnum.WORK_PLANNINGS &&
                                                (entity.stateCode !== ProjectStateCodeEnum.NEW ? (
                                                    <OperationTypeSelectButton
                                                        disabled={isProjectCancelled}
                                                        onChangeOperationType={async (
                                                            code: OperationTypeCodeEnum,
                                                            correctionCode?: RentStateCodeEnum | undefined
                                                        ) => {
                                                            this.createNewOperation(code, correctionCode);
                                                        }}
                                                        projectId={entity.id}
                                                        tooltipTitle={
                                                            isProjectCancelled ? 'В отмененном проекте действие недоступно' : undefined
                                                        }
                                                    />
                                                ) : (
                                                    <RoundButton
                                                        className={'ant-btn-page-actions'}
                                                        colorScheme={'DRAFT'}
                                                        onClick={this.onCreateDraftButtonClick}
                                                    >
                                                        <Icon component={IconBorderStyleSolid} />
                                                        Рассчитать черновик
                                                    </RoundButton>
                                                ))}

                                            {this.props.tab === ProjectPageTabsEnum.TRANSPORTATIONS && (
                                                <Tooltip
                                                    title={isProjectCancelled ? 'Действие недоступно в отмененном проекте' : undefined}
                                                >
                                                    <div>
                                                        <RoundButton
                                                            onClick={this.createTransportation}
                                                            icon={'plus'}
                                                            colorScheme={'success'}
                                                            disabled={isProjectCancelled}
                                                        >
                                                            Создать перевозку
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {this.props.tab === ProjectPageTabsEnum.EXPENSES && (
                                                <Tooltip
                                                    title={isProjectCancelled ? 'В отмененном проекте действие недоступно' : undefined}
                                                >
                                                    {/* Необходимо обернуть кнопку в div чтобы тултип работал (antd) */}
                                                    <div>
                                                        <RoundButton
                                                            onClick={this.createExpense}
                                                            icon={'plus'}
                                                            colorScheme={'success'}
                                                            disabled={isProjectCancelled}
                                                        >
                                                            Создать затрату
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {this.props.tab === ProjectPageTabsEnum.WORK_PLANNINGS && (
                                                <Tooltip
                                                    title={isProjectCancelled ? 'В отмененном проекте действие недоступно' : undefined}
                                                >
                                                    {/* Необходимо обернуть кнопку в div чтобы тултип работал (antd) */}
                                                    <div>
                                                        <RoundButton
                                                            onClick={this.createWorkPlanning}
                                                            icon={'plus'}
                                                            colorScheme={'success'}
                                                            disabled={isProjectCancelled}
                                                        >
                                                            Создать работы
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {this.props.tab === ProjectPageTabsEnum.OFFERS && (
                                                <Tooltip
                                                    title={isProjectCancelled ? 'Действие недоступно в отмененном проекте' : undefined}
                                                >
                                                    <div>
                                                        <RoundButton
                                                            onClick={this.createOffer}
                                                            icon={'plus'}
                                                            colorScheme={'success'}
                                                            disabled={isProjectCancelled}
                                                        >
                                                            Создать смету
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {entity?.stateCode === ProjectStateCodeEnum.NEW && (
                                                <Tooltip
                                                    title={
                                                        !isActivateActionAvailable
                                                            ? 'Арендатор в неактивном статусе, действие недоступно'
                                                            : undefined
                                                    }
                                                >
                                                    <div>
                                                        <RoundButton
                                                            colorScheme={'inRent'}
                                                            onClick={this.onChangeStatusClick}
                                                            disabled={!isActivateActionAvailable}
                                                        >
                                                            <Icon component={IconRocket} />
                                                            {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__START, 'span')}
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            )}

                                            {entity.stateCode === ProjectStateCodeEnum.FINISHED ||
                                            entity.stateCode === ProjectStateCodeEnum.CANCELED ? (
                                                <Tooltip
                                                    title={
                                                        !isActivateActionAvailable
                                                            ? 'Арендатор в неактивном статусе, действие недоступно'
                                                            : undefined
                                                    }
                                                >
                                                    <div>
                                                        <RoundButton
                                                            colorScheme={'inRent'}
                                                            onClick={this.onChangeStatusClick}
                                                            disabled={!isActivateActionAvailable}
                                                        >
                                                            <Icon component={IconReply} />
                                                            {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__RESUME, 'span')}
                                                        </RoundButton>
                                                    </div>
                                                </Tooltip>
                                            ) : null}

                                            <span style={{ marginLeft: '4px' }}>
                                                <AdditionalEntityActions
                                                    ref={(ref) => (this.additionalEntityActionsRef = ref)}
                                                    dontClose={this.state.selectTemplatePopoverOpened}
                                                    disabled={false}
                                                    content={
                                                        <>
                                                            {
                                                                <Button className={'ant-btn-page-actions'} onClick={this.onEditButtonClick}>
                                                                    <Icon component={IconEdit} />
                                                                    <span>{localize(LocalizationEnum.ASPECT__ACTION__EDIT, 'span')}</span>
                                                                </Button>
                                                            }

                                                            {offersModuleEnabled && (
                                                                <Tooltip
                                                                    placement={'left'}
                                                                    title={
                                                                        isProjectCancelled
                                                                            ? 'Действие недоступно в отмененном проекте'
                                                                            : undefined
                                                                    }
                                                                >
                                                                    <div>
                                                                        <Button
                                                                            className={'ant-btn-page-actions'}
                                                                            onClick={this.createOffer}
                                                                            disabled={isProjectCancelled}
                                                                        >
                                                                            <Icon component={IconPlus} style={{color: '#34bfa3'}}/>
                                                                            Создать смету
                                                                        </Button>
                                                                    </div>
                                                                </Tooltip>
                                                            )}

                                                            {entity?.stateCode !== ProjectStateCodeEnum.NEW &&
                                                            canViewFinancialData &&
                                                            canUpdateFinancialData ? (
                                                                <Button className={'ant-btn-page-actions'} onClick={this.onPay}>
                                                                    <SystemIcon type={SystemIconType.financial} />
                                                                    {localize(
                                                                        LocalizationEnum.PAGE__PROJECTS__ACTIONS__ACCEPT_PAYMENT,
                                                                        'span'
                                                                    )}
                                                                </Button>
                                                            ) : null}

                                                            <Button className={'ant-btn-page-actions'} onClick={this.onCopyButtonClick}>
                                                                <Icon
                                                                    component={IconControlPointDuplicateBlack}
                                                                    style={{ color: '#34bfa3' }}
                                                                />
                                                                {localize(LocalizationEnum.ASPECT__ACTION__DUPLICATE, 'span')}
                                                            </Button>

                                                            <AddProjectPopover1
                                                                onClose={() => {
                                                                    this.props.push(
                                                                        `/${this.props.businessAccountId}/projects/production/${this.props.entityId}`
                                                                    );
                                                                    this.props.loadEntities(
                                                                        this.props.intl,
                                                                        this.props.businessAccountId,
                                                                        'project'
                                                                    );
                                                                    if (this.additionalEntityActionsRef)
                                                                        this.additionalEntityActionsRef.close();
                                                                }}
                                                                onVisibleChange={(visible) => {
                                                                    this.setState({ selectTemplatePopoverOpened: visible });
                                                                }}
                                                                projectId={entity?.id}
                                                            >
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    onClick={this.onAddFromTemplateButtonClick}
                                                                >
                                                                    <Icon component={IconScrollSolid} style={{ color: '#00afee' }} />
                                                                    <span>Добавить по шаблону</span>
                                                                </Button>
                                                            </AddProjectPopover1>

                                                            {entity.stateCode === ProjectStateCodeEnum.INPROGRESS &&
                                                            entity.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.FINISH) ? (
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    onClick={this.onChangeStatusClick}
                                                                >
                                                                    <Icon component={IconFlagCheckered} style={{ color: '#34bfa3' }} />
                                                                    {localize(LocalizationEnum.PAGE__PROJECTS__ACTIONS__FINISH, 'span')}
                                                                </Button>
                                                            ) : null}

                                                            {entity.archivable ? (
                                                                !entity.archive ? (
                                                                    <Button
                                                                        className={'ant-btn-page-actions'} //#c4c5d6
                                                                        onClick={this.onArchiveButtonClick}
                                                                    >
                                                                        <Icon component={IconArchive} style={{ color: '#c4c5d6' }} />
                                                                        {localize(LocalizationEnum.ASPECT__ACTION__TO_ARCHIVE, 'span')}
                                                                    </Button>
                                                                ) : (
                                                                    <Button
                                                                        className={'ant-btn-page-actions'} //#00c5dc
                                                                        onClick={this.onArchiveButtonClick}
                                                                    >
                                                                        <Icon component={IconLevelUp} style={{ color: '#00c5dc' }} />
                                                                        {localize(LocalizationEnum.ASPECT__ACTION__FROM_ARCHIVE, 'span')}
                                                                    </Button>
                                                                )
                                                            ) : null}

                                                            {entity.availableTransitionCodes?.includes(ProjectTransitionCodeEnum.CANCEL) ? (
                                                                <Button
                                                                    className={'ant-btn-page-actions'}
                                                                    onClick={this.onCancelButtonClick}
                                                                >
                                                                    <Icon component={IconEraser} style={{ color: '#A09FA0' }} />
                                                                    Отменить
                                                                </Button>
                                                            ) : null}
                                                        </>
                                                    }
                                                >
                                                    <AdditionalEntityActionsButton />
                                                </AdditionalEntityActions>
                                            </span>
                                        </>
                                    }
                                >
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__FIELDS__COMMON__DESCRIPTION)}
                                        key={ProjectPageTabsEnum.DESCRIPTION}
                                    >
                                        <ProjectPageDescriptionTabPane entity={entity} />
                                    </Tabs.TabPane>
                                    {offersModuleEnabled && (
                                        <Tabs.TabPane tab={'Сметы'} key={ProjectPageTabsEnum.OFFERS}>
                                            <ProjectOffersTab
                                                visible={this.props.tab === ProjectPageTabsEnum.OFFERS}
                                                projectId={entity.id}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__ELEMENTS)}
                                        key={ProjectPageTabsEnum.ELEMENTS}
                                    >
                                        <ElementsList
                                            gridName={'project-elements'}
                                            visible={this.props.tab === ProjectPageTabsEnum.ELEMENTS}
                                            parentType={'project'}
                                            parentId={entity.id}
                                            location={this.props.location}
                                        />
                                    </Tabs.TabPane>
                                    {crewModuleEnabled && (
                                        <Tabs.TabPane tab={'Работы'} key={ProjectPageTabsEnum.WORK_PLANNINGS}>
                                            <WorkPlanningsList
                                                gridName={ProjectPageGridNamesEnum.WORK_PLANNINGS}
                                                visible={this.props.tab === ProjectPageTabsEnum.WORK_PLANNINGS}
                                                projectId={entity.id}
                                                parentProjectEntity={entity}
                                                urlPath={`projects/production/${this.props.entityId}?tab=workPlannings`}
                                                staticPageParams={{
                                                    projectId: entity.id,
                                                }}
                                                modalProps={{
                                                    initialValues: {
                                                        project: entity.id,
                                                        projectStateCode: entity.stateCode,
                                                    },
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    {canViewFinancialData && expensesModuleEnabled && (
                                        <Tabs.TabPane tab={'Затраты'} key={ProjectPageTabsEnum.EXPENSES}>
                                            <ExpensesList
                                                gridName={ProjectPageGridNamesEnum.EXPENSES}
                                                visible={this.props.tab === ProjectPageTabsEnum.EXPENSES}
                                                parentProjectEntity={entity}
                                                urlPath={`projects/production/${this.props.entityId}?tab=expenses`}
                                                gridProps={{
                                                    filtersExcludeFields: ['projectId'],
                                                    excludeColumns: ['projectId'],
                                                }}
                                                staticPageParams={{
                                                    projectId: entity.id,
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    {logisticsModuleEnabled && (
                                        <Tabs.TabPane tab={'Перевозки'} key={ProjectPageTabsEnum.TRANSPORTATIONS}>
                                            <TransportationsList
                                                gridName={ProjectPageGridNamesEnum.TRANSPORTATIONS}
                                                visible={this.props.tab === ProjectPageTabsEnum.TRANSPORTATIONS}
                                                projectId={entity.id}
                                                urlPath={`projects/production/${this.props.entityId}?tab=transportations`}
                                                gridProps={{
                                                    filtersExcludeFields: ['projectId'],
                                                    excludeColumns: ['projectId'],
                                                }}
                                                staticPageParams={{
                                                    projectId: entity.id,
                                                }}
                                                modalProps={{
                                                    initialValues: {
                                                        project: entity.id,
                                                    },
                                                }}
                                            />
                                        </Tabs.TabPane>
                                    )}
                                    <Tabs.TabPane
                                        tab={localize(LocalizationEnum.ASPECT__ENTITY_TYPE__OPERATIONS)}
                                        key={ProjectPageTabsEnum.OPERATIONS}
                                        disabled={false}
                                    >
                                        <OperationsList
                                            gridName={'project-operations'}
                                            location={this.props.location}
                                            projectId={entity.id}
                                            parentEntityType={'project'}
                                        />
                                    </Tabs.TabPane>
                                </Tabs>
                            </div>
                        ) : null}
                    </div>
                </Spin>

                {this.props.editMode ? (
                    <SimpleOrderCreateModal
                        title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__EDIT)}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity as { [k: string]: any }}
                        filters={editSimpleOrderModalFilters}
                        modalName={'edit-project'}
                    />
                ) : null}

                {entity && this.props.copyMode ? (
                    <SimpleOrderCreateModal
                        okButtonText={localize(LocalizationEnum.ASPECT__GLOBAL__CREATE)}
                        validateAfterCreate={true}
                        title={localize(LocalizationEnum.PAGE__PROJECTS__FORM__FORM_TITLE__CREATE)}
                        copyMode={true}
                        businessAccountId={this.props.businessAccountId}
                        initialValues={entity}
                        filters={createSimpleOrderModalFilters}
                        modalName={'create-project'}
                    />
                ) : null}
                {this.props.payMode && <PaymentCreateModal project={entity} />}

                {this.props.newOfferMode && entity && (
                    <OfferCreateModalWrapper
                        projectId={entity.id}
                        onSuccess={() => {
                            this.props.loadEntity(this.props.businessAccountId, this.props.entityId);
                        }}
                    />
                )}
            </>
        );
    }
}

const mapStateToProps = (storeState: IRootState, ownProps: RouteComponentProps) => {
    let searchParams = getStateFromPath(ownProps.location.search);
    return {
        entity: storeState.simpleOrder.entity,
        loading: storeState.simpleOrder.loading,
        updating: storeState.simpleOrder.updating,

        loadingError: storeState.simpleOrder.loadingError,
        businessAccountId: +ownProps.match.params['businessAccountId'] || 0,
        entityId: +ownProps.match.params['id'] || 0,
        editMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'edit'),
        payMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'pay'),

        copyMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'copy'),
        newOfferMode: !!(storeState.simpleOrder.entity && ownProps.match.params['action'] === 'newOffer'),
        tab:
            searchParams.tab &&
            (searchParams.tab === ProjectPageTabsEnum.DESCRIPTION ||
                searchParams.tab === ProjectPageTabsEnum.OPERATIONS ||
                searchParams.tab === ProjectPageTabsEnum.OFFERS ||
                searchParams.tab === ProjectPageTabsEnum.ELEMENTS ||
                searchParams.tab === ProjectPageTabsEnum.TRANSPORTATIONS ||
                searchParams.tab === ProjectPageTabsEnum.EXPENSES ||
                searchParams.tab === ProjectPageTabsEnum.WORK_PLANNINGS)
                ? searchParams.tab
                : ProjectPageTabsEnum.ELEMENTS,
        operationFormMnemoKey: storeState.operationForm.mnemoKey,
        defaultShiftLengthInMinutes: storeState.businessAccountPreferences.preferences?.shiftLengthInMinutes,
        shiftCountRoundingType: storeState.businessAccountPreferences.preferences?.shiftCountRoundingType,
        canViewFinancialData: canViewFinancialData(storeState.permissions.permissions),
        canUpdateFinancialData: canUpdateFinancialData(storeState.permissions.permissions),
        currentOperationUUID: storeState.operationForm.currentOperationUUID,
        tabFromStore: getCurrentTabsSelector(storeState)?.['project'],
        logisticsModuleEnabled: logisticsModuleEnabledSelector(storeState),
        crewModuleEnabled: crewModuleEnabledSelector(storeState),
        statisticsModuleEnabled: statisticsModuleEnabledSelector(storeState),
        offersModuleEnabled: offersModuleEnabledSelector(storeState),
        expensesModuleEnabled: expensesModuleEnabledSelector(storeState),
    };
};

const mapDispatchToProps = {
    loadEntity,
    updateEntity,
    setStatusAndArchive,
    reset,
    push,
    replace,
    setRenterAndProject,
    resetOperationForm,
    startNewOperation,
    loadEntities,
    fullResetOperation,
    removeConcurrentOperation,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SimpleOrderPage);
