import { LocalizationEnum, localize } from '../../../../localization';

import { RentersPageTabsEnum } from '../../../../shared/constants/tabEnums';

export const linkToCRMPage = (businessAccountId: number) => `/${businessAccountId}/${rentersPageUrlRoute}`;
export const linkToCounterpartiesPage = ({businessAccountId}:{businessAccountId: number}) => `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.COUNTERPARTIES}`;
export const linkToCounterpartyPage = ({businessAccountId, counterpartyId}:{businessAccountId: number; counterpartyId: number}) => `${linkToCounterpartiesPage({businessAccountId})}/${counterpartyId}`;
export const linkToCounterpartyProjectsPage = ({businessAccountId, counterpartyId}:{businessAccountId: number; counterpartyId: number}) => `${linkToCounterpartyPage({businessAccountId, counterpartyId})}?tab=projects`;
export const linkToCounterpartySimpleProjectsPage = ({businessAccountId, counterpartyId}:{businessAccountId: number; counterpartyId: number}) => `${linkToCounterpartyPage({businessAccountId, counterpartyId})}?tab=projects`;
export const linkToCounterpartyShippingsPage = ({businessAccountId, counterpartyId}:{businessAccountId: number; counterpartyId: number}) => `${linkToCounterpartyPage({businessAccountId, counterpartyId})}?tab=shippings`;
export const linkToContactsPage = ({businessAccountId}:{businessAccountId: number}) => `/${businessAccountId}/${rentersPageUrlRoute}/${RentersPageTabsEnum.CONTACTS}`;
export const rentersPageUrlRoute = 'crm';
export const defaultTab = RentersPageTabsEnum.COUNTERPARTIES;

export const tabToCreateButtonText = {
    [RentersPageTabsEnum.COUNTERPARTIES]: localize(LocalizationEnum.ASPECT__ACTION__CREATE_RENTER, 'span'),
    [RentersPageTabsEnum.CONTACTS]: 'Создать контакт',
};
