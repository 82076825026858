import React, { ClassicComponent, Component, FC,  useEffect, useRef, useState } from 'react';
import { DatePicker, Input } from 'antd';
import { DatePickerProps } from 'antd/lib/date-picker/interface';
import moment, { Moment } from 'moment';import ReactDOM from 'react-dom';
import {TodayButton} from "../../v2/calendar/rangePicker/components/todayButton/todayButton";

export interface DatePickerWithManualInputProps {
    onChange: (value: DatePickerProps['value']) => void;
    value?: DatePickerProps['value'] | string;
    pickerProps?: DatePickerProps;
    getPopupContainer?: (triggerNode: Element) => HTMLElement;
    suppressedModes?: DatePickerProps['mode'][];
    defaultPickerMode?: DatePickerProps['mode'];
}

const format1 = 'DD.MM.YY';
const format2 = 'DD.MM.YYYY';

const DatePickerWithManualInputFunction: FC<DatePickerWithManualInputProps> = (props) => {
    const { value, onChange, getPopupContainer, pickerProps, suppressedModes, defaultPickerMode } = props;
    const formValue = typeof value === 'string' ? moment(value) : value;
    const [currentFormat, setCurrentFormat] = useState<typeof format1 | typeof format2>(format2);
    const [inputValue, setInputValue] = useState(formValue ? formValue.format(currentFormat) : '');
    const inputValueBeforeRef = useRef<typeof inputValue | undefined>(undefined);
    const [inputFocusStatus, setInputFocusStatus] = useState(false);
    const inputRef = useRef<Input>(null);

    const [pickerValue, setPickerValue] = useState<DatePickerProps['value']>(null);
    const [pickerMode, setPickerMode] = useState<DatePickerProps['mode']>(defaultPickerMode ?? formValue ? 'date' : 'year');
    const [pickerOpenStatus, setPickerOpenStatus] = useState<DatePickerProps['open']>(false);
    const [pickerFocusStatus, setPickerFocusStatus] = useState(false);
    const pickerRef = useRef<ClassicComponent<DatePickerProps, any>>(null);

    const formIsActive = inputFocusStatus || pickerFocusStatus || pickerOpenStatus;

    const onPanelChange: DatePickerProps['onPanelChange'] = (value, mode) => {
        if(pickerMode === 'year' && (mode === 'date' || mode === null)) mode = 'month';
        if (suppressedModes?.includes(mode)) mode = pickerMode!;
        setPickerMode(mode);
    };

    const handleInputChange = (value: string): string => {
        const newInputValue = value
            .replace(/\D/g, '') // Удаляем все нечисловые символы
            .replace(/^(\d{2})(\d)/, '$1.$2') // Добавляем точку после дня
            .replace(/^(\d{2}\.\d{2})(\d)/, '$1.$2') // Добавляем точку после месяца
            .replace(/^(\d{2}\.\d{2}\.\d{4})\d+/, '$1'); // Удаляем лишние символы после года

        setInputValue(newInputValue);

        return newInputValue;
    };

    const setDateInputCurrentFormat = (dateString: string): typeof currentFormat | undefined => {
        if (moment(dateString, format2, true).isValid()) {
            setCurrentFormat(format2);
            return format2;
        } else if (moment(dateString, format1, true).isValid()) {
            setCurrentFormat(format1);
            return format1;
        }
    };

    const formChangeHandler = (newValue: string | Moment | null, newFormat?: typeof currentFormat) => {
        if (newValue == null || newValue === '') {
            onChange(undefined);
            return;
        }

        if (typeof newValue === 'string') {
            onChange(moment(newValue, newFormat ?? currentFormat));
        } else {
            onChange(newValue);
        }
    };

    const dataPickerOnChangeHandler = (date: Moment | null, dateString: string) => {
        const newInputValue = handleInputChange(date?.format(currentFormat) ?? '');
        const newFormat = setDateInputCurrentFormat(newInputValue);

        if (newFormat != null) {
            setPickerValue(moment(newInputValue, newFormat));
        }
        formChangeHandler(newInputValue);
    };

    useEffect(() => {
        dataPickerOnChangeHandler(formValue ?? null, '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (formIsActive) {
            if (inputValueBeforeRef.current == null) {
                inputValueBeforeRef.current = inputValue;
            }
        } else {
            inputValueBeforeRef.current = undefined;
        }
    }, [formIsActive, inputValue]);

    useEffect(() => {
        const onKeyDown = (event: KeyboardEvent) => {
            const closeForm = () => {
                setInputFocusStatus(false);
                setInputFocusStatus(false);
                setPickerFocusStatus(false);
                setPickerOpenStatus(false);
                inputRef.current?.blur();
            };

            if (formIsActive) {
                event.stopPropagation();
                if (event.key === 'Escape') {
                    setInputValue(inputValueBeforeRef.current ?? '');
                    closeForm();
                }
                if (event.key === 'Enter') {
                    closeForm();
                }
            }
        };

        const inputNode = ReactDOM.findDOMNode(inputRef.current);
        document.addEventListener('keydown', onKeyDown, false);
        inputNode?.addEventListener('keydown', onKeyDown as EventListener, false);

        return () => {
            document.removeEventListener('keydown', onKeyDown, false);
            inputNode?.removeEventListener('keydown', onKeyDown as EventListener, false);
        };
    });

    return (
        <>
            <Input
                ref={inputRef}
                placeholder={'Введите дату'}
                style={{ width: 207, zIndex: 100, position: 'absolute' }}
                value={inputValue}
                onChange={(event) => {
                    event.preventDefault();
                    const newValue = event.target.value;

                    const newInputValue = handleInputChange(newValue);
                    const newFormat = setDateInputCurrentFormat(newInputValue);

                    if (newFormat != null) {
                        setPickerValue(moment(newValue, newFormat));
                        setPickerMode('date');
                    }
                    formChangeHandler(newValue, newFormat);
                }}
                onFocus={(e) => {
                    setInputFocusStatus(true);
                    setPickerOpenStatus(true);
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setInputFocusStatus(false);
                    }, 50);
                }}
                maxLength={10}
                allowClear={true}
            />
            <DatePicker
                {...(pickerProps ?? {})}
                ref={pickerRef}
                value={pickerValue ?? formValue}
                mode={pickerMode}
                onPanelChange={onPanelChange}
                open={formIsActive}
                onOpenChange={(openStatus) => {
                    setPickerOpenStatus(openStatus);
                }}
                onChange={dataPickerOnChangeHandler}
                onFocus={() => {
                    setPickerFocusStatus(true);
                }}
                onBlur={() => {
                    setTimeout(() => {
                        setPickerFocusStatus(false);
                    }, 50);
                }}
                showToday={false}
                renderExtraFooter={(mode)=>(
                    mode === 'date' ? <TodayButton
                        onClick={()=>{
                            dataPickerOnChangeHandler(moment().startOf('day'), '');
                        }}
                        style={{position: "absolute", zIndex: 11, top: 20.5, right: 68}}
                    /> : null
                )}
                format={currentFormat}
                style={{
                    visibility: 'hidden',
                    zIndex: 10,
                }}
                getCalendarContainer={getPopupContainer}
                {...{ align: { points: ['bl', 't'], offset: [0, -6] } }}
            />
        </>
    );
};

export class DatePickerWithManualInput extends Component<DatePickerWithManualInputProps> {
    render() {
        return <DatePickerWithManualInputFunction {...this.props} />;
    }
}
