import React from 'react';
import moment, {Moment} from "moment";
import {DatePickerModified, DatePickerModifiedProps} from "./DatePickerModified";
import {TodayButton} from "../v2/calendar/rangePicker/components/todayButton/todayButton";
import {getCurrentServerTime} from "../../shared/reducers/system.reducer";

export class DatePicker1 extends React.PureComponent<DatePickerModifiedProps> {
    render() {
        return (
            <DatePickerModified
                {...this.props}
                onChange={(date: Moment | null, dateString: string) => {
                    if (date) date = date.clone().hour(0).minutes(0).seconds(0).milliseconds(0);
                    if (this.props.onChange) this.props.onChange(date, dateString);
                }}
                {...{ align: { points: ['bl', 't'], offset: [0, -6] } }}
                showToday={false}
                renderExtraFooter={()=>(
                    <TodayButton
                        onClick={()=>{
                            const today = moment(getCurrentServerTime()).startOf('day');
                            this.props.onChange?.(today, '');
                        }}
                        style={{position: "absolute", zIndex: 11, top: 20.5, right: 68}}
                    />
                )}
            />
        );
    }
}
