import React, {FC} from 'react';
import {AttachmentParentEntityTypeCodeEnum, ProjectInfoRead} from "../../../../../../../../server";
import {useAppDispatch} from "../../../../../../../../store/hooks";
import {Col, Row} from "antd";
import {AttachmentsBlock} from "../../../../../../../../components/attachmentsBlock/attachmentsBlock";
import {loadEntity} from "../../../../reducers/simpleOrder.reducer";

interface ProjectPageDescriptionAttachmentsBlockProps {
    data: ProjectInfoRead;
}

export const ProjectPageDescriptionAttachmentsBlock:FC<ProjectPageDescriptionAttachmentsBlockProps> = ({data}) => {

    const dispatch = useAppDispatch();

    return (
        <Row>
            <Col style={{ marginLeft: -8, marginRight: -8, borderBottom: '1px solid #eaeaea' }}>
                <AttachmentsBlock
                    parentEntityId={data.id}
                    parentEntityTypeCode={AttachmentParentEntityTypeCodeEnum.PROJECT}
                    attachments={data.attachments?.records || []}
                    updateParentEntity={() => {
                        dispatch(loadEntity(data.businessAccountId, data.id));
                    }}
                />
            </Col>
        </Row>
    );
};
